import React from "react"
import PropTypes from "prop-types"
import { ProjectItemStyles } from "./styles"
import { Col, Row } from "antd"

const ProjectItem = ({ onClick, item }) => {
  const countArtists = item.artists?.length

  return (
    <ProjectItemStyles onClick={onClick}>
      <div className="dot-16 bg-secondary"></div>
      <div className="project-item__name">
      <h3 style={{ marginBottom: 10 }}>{item.displayName?.vi}</h3>
      <h3>{item.displayName?.en}</h3>
      </div>
      

      <Row gutter={[20, 20]}>
        {item.artists?.map((artist, idx) => (
          <Col
            span={countArtists > 3 ? 8 : 24 / countArtists}
            key={String(idx)}
            className="project-item__artist"
          >
            <h5 className="project-item__artist-name">{artist.fullName}</h5>
            <h5 className="project-item__artist-country">{artist.country}</h5>
          </Col>
        ))}
      </Row>
    </ProjectItemStyles>
  )
}

ProjectItem.propTypes = {
  item: PropTypes.object,
}

export default ProjectItem
