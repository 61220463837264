import styled from 'styled-components';

const PageContentStyles = styled.div`
  margin-top: 100px;

  @media only screen and (max-width: 425px) {
    margin-top: 60px;
  }
`;

export default PageContentStyles;