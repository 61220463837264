import React from "react"

import PublicLayout from "components/layouts/PublicLayout"
import Seo from "components/seo"
import Artists from 'containers/Artists'

const ArtistsPage = () => (
  <PublicLayout>
    <Seo title="Artists" />

    <Artists />
  </PublicLayout>
)

export default ArtistsPage
