import React, { useRef } from "react"
import { Col, Row } from "antd"

import { ARTWORKS } from "data/artworks"

import PageTitle from "components/common/PageTitle"
import PageContent from "components/common/PageContent"
import ProjectItem from "containers/Artists/ProjectItem"
import BottomOverlay from "containers/Artists/BottomOverlay"
import ArtistDetails from "../ArtistDetails"

const Artists = () => {
  const artistDetailRef = useRef()

  const openProjectDetail = id => {
    artistDetailRef.current.open(id)
  }

  return (
    <div>
      <div className="container-landing container-wrapper">
        <PageTitle title="Nghệ sĩ / Artists" />

        <PageContent>
          <Row gutter={[30, 20]}>
            {ARTWORKS.map((item, idx) => (
              <Col key={String(idx)} xl={8} lg={8} md={12} xs={24} sm={24}>
                <ProjectItem
                  item={item}
                  onClick={() => openProjectDetail(item.id)}
                />
              </Col>
            ))}
          </Row>
        </PageContent>
      </div>
      <BottomOverlay />
      <ArtistDetails ref={artistDetailRef} />
    </div>
  )
}

Artists.propTypes = {}

export default Artists
