import styled from "styled-components"

const PageTitleStyles = styled.h1`
  font-size: 100px;
  line-height: 100px;

  @media only screen and (max-width: 992px) {
    font-size: 80px;
  }

  @media only screen and (max-width: 425px) {
    font-size: 60px;
  }
`

export default PageTitleStyles
