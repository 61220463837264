import React from 'react';

import PageContentStyles from './styles';

const PageContent = ({children, ...props}) => {
  return (
    <PageContentStyles>{children}</PageContentStyles>
  )
}

export default PageContent;